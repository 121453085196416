import { SmartForm } from '@certemy/ui-components';

export const constants = Object.freeze({
  // TODO: use from constants/status.ts, remove this after change everywhere
  STEP_TYPES: {
    DYNAMIC: 1,
    FEES: 2,
    OTHER_CERTIFICATE: 3, // step was removed
    EVIDENCE_FILE: 4,
    EDUCATION: 5,
    TEST: 6,
    REGISTRATION_FORM: 7,
    VERIFICATION: 8,
    LOG: 9,
  },
  // TODO: use from constants/status.ts, remove this after change everywhere
  STEP_TYPES_NAMES: {
    DYNAMIC_FORM_NAME: 'Dynamic form',
    REGISTRATION_FORM: 'Registration Form',
    FEES: 'Fees',
    TEST: 'Test',
  },
  STEP_CONTENT_ELEMENTS_TYPES: {
    TEXT: 1,
    EMAIL: 2,
    RADIO_OPTIONS: 3,
    CHECKBOX: 4,
    SELECT: 5,
    PASSWORD: 6,
    NUMBER: 7,
    DATE: 8,
    ATTACHMENT: 10,
    MULTI_SELECT: 11,
    PHONE_NUMBER: 12,
    ADA_CODES: 13,
    SELECT_COUNTRY: 17,
    SELECT_STATE: 18,
  },
  CERTIFICATE_STATUSES: {
    IMPOSED: 37,
    ARCHIVED: 38,
    ACCEPT: 40,
    REJECT: 41,
    COMPLETE: 43,
    PENDING_VERIFICATION: 42,
    UNDER_AUDIT: 82,
    CORRECTIONS_REQUESTED: 83,
    CREDENTIAL_PAUSED: 84,
    // Due to ICERT01-3224
    DISABLED: 44,
  },
  CERTIFICATE_STATUSES_NAME: {
    37: 'Imposed',
    38: 'Archived',
    40: 'Incomplete',
    41: 'Accepted',
    43: 'Completed',
    42: 'Pending verification',
    82: 'Pending verification',
    83: 'Corrections Requested',
    84: 'Credential Paused',
    44: 'Accepted',
  },
  STEP_TYPE_STATUSES: {
    INCOMPLETE: 47,
    COMPLETED: 48,
    PENDING_VERIFICATION: 49,
    PASSED_DUE_DATE: 50,
    PARTIALLY_COMPLETED: 75,
    ARCHIVED: 91,
  },
  STEP_TYPE_STATUS_NAMES: {
    47: 'Incomplete',
    48: 'Completed',
    49: 'Pending verification',
    50: 'Passed due date',
    75: 'Partially completed',
    91: 'Archived'
  },
  STEP_AUDIT_STATUSES: {
    UNDER_AUDIT: 88,
    CORRECTIONS_REQUESTED: 89,
    CREDENTIAL_PAUSED: 90,
  },
  STEP_AUDIT_STATUS_NAMES: {
    88: 'Pending Verification',
    89: 'Corrections Requested',
    90: 'Credential Paused',
  },
  EDUCATIONAL_ITEM_STATUSES: {
    ASSIGNED: 54,
    SIGNED_UP: 55,
    COMPLETED: 56,
  },
  TEST_REGISTRATION_STATUSES: {
    INVITED: 64,
    PRE_REGISTERED: 57,
    SCHEDULED: 63,
    COMPLETED: 58,
    NO_SHOW: 60,
    FAILED: 59,
    RESCHEDULED: 62,
    CANCELLED: 61,
    ADA: 'ada',
  },
  SCORE_EXAM_STATUSES_NAMES: {
    64: 'Invited',
    57: 'Pre-Registered',
    63: 'Scheduled',
    58: 'Pass',
    59: 'Fail',
    60: 'No Show',
    62: 'Rescheduled',
    61: 'Cancelled',
  },

  FORM_FIELD_TYPE_IDS: {
    HTML_EDITOR: -1,
    TEXT: 1,
    EMAIL: 2,
    RADIO: 3,
    CHECKBOX: 4,
    SELECT: 5,
    NUMBER: 7,
    DATE: 8,
    ATTACHMENT: 10,
    MULTI_SELECT: 11,
    PHONE_NUMBER: 12,
    ADA_CODES: 13,
  },
  // todo !!After adding a new value in FORM_FIELD_TYPE_IDS_ADDITIONAL, Update mapFieldSubTypeToType and mapFieldTypeToSubType functions
  FORM_FIELD_TYPE_IDS_ADDITIONAL: {
    EXAM: 14,
    VALID_FROM: 15,
    VALID_TO: 16,
    COUNTRY: 17,
    STATE: 18,
    ZIP_CODE: 19,
    PHYSICAL_MAILING_ADDRESS: 20,
    CITY: 21,
    LICENSE_VERIFICATION_STATUS: 22,
    LICENSE_VERIFICATION_STATUS_NOTES: 23,
    LICENSE_RENEWAL_STATUS: 24,
    LICENSE_RENEWAL_STATUS_NOTES: 25,
    LICENSE_TYPE: 26,
    BIRTH_DATE: 27,
    SOCIAL_SECURITY_NUMBER: 28,
    EMPLOYER_IDENTIFICATION_NUMBER: 29,
    NATIONAL_PROVIDER_IDENTIFIER: 30,
    EXPIRATION_DATE: 31,
    LICENSE_NUMBER: 32,
    ISSUE_DATE: 33,
    ORIGINAL_ISSUE_DATE: 34,
    DISCIPLINARY_ISSUE: 35,
    DISCIPLINARY_URL: 36,
  },
  // todo !!After adding a new value in FORM_FIELD_TYPE_IDS_ADDITIONAL, Update mapFieldSubTypeToType and mapFieldTypeToSubType functions
  SELECT_SUB_TYPES: {
    EXAM: 1,
    COUNTRY: 2,
    STATE: 3,
    LICENSE_VERIFICATION_STATUS: 4,
    LICENSE_RENEWAL_STATUS: 5,
    LICENSE_TYPE: 6,
    DISCIPLINARY_ISSUE: 7,
  },
  DATE_SUB_TYPES: {
    VALID_FROM: 1,
    VALID_TO: 2,
    BIRTH_DATE: 3,
    EXPIRATION_DATE: 4,
    ISSUE_DATE: 5,
    ORIGINAL_ISSUE_DATE: 6,
  },
  TEXT_SUB_TYPES: {
    ZIP_CODE: 1,
    PHYSICAL_MAILING_ADDRESS: 2,
    CITY: 3,
    LICENSE_VERIFICATION_STATUS_NOTES: 4,
    LICENSE_RENEWAL_STATUS_NOTES: 5,
    SOCIAL_SECURITY_NUMBER: 6,
    EMPLOYER_IDENTIFICATION_NUMBER: 7,
    NATIONAL_PROVIDER_IDENTIFIER: 8,
    LICENSE_NUMBER: 9,
    DISCIPLINARY_URL: 10,
  },
  COURSE_STATUSES: {
    STATUS_ID_PENDING: 51,
    STATUS_ID_PUBLISH: 52,
    STATUS_ID_DRAFT: 53,
    COURSES_PAGE_SIZE: 6,
  },
  CERTIFICATION_GENERAL_STATUSES: {
    ACTIVE: 1,
    DELETED: 2,
    INACTIVE: 3,
  },
  CERTIFICATION_TEMPLATE: {
    GENERAL: 1,
    TEST_REGISTRATION: 2,
  },
  CERTIFICATION_ACCESS_LEVEL: {
    PRIVATE: { id: 1, name: 'Private' },
    PUBLIC: { id: 2, name: 'Public' },
  },
  CERTIFICATION_TEMPLATE_NAMES: {
    GENERAL: 'General',
    TEST_REGISTRATION: 'Test Registration',
  },
  TEST_REGISTRATION_PHASES: {
    REGISTRATION_FORM: 'Registration',
    TEST: 'Test',
  },
  TEST_REGISTRATION_PHASES_ID: {
    REGISTRATION_FORM: 2,
    TEST: 3,
  },
  ASSIGMENT_STATUS: {
    DRAFT: 35,
    LIVE: 36,
  },
  ASSIGMENT_TEMPLATE: {
    GENERAL: 1,
    TEST_REGISTRATION: 2,
  },
  FILE_DEFAULT_MAX_SIZE: 30,
  MEGABYTE: 1024 * 1024,
  KILOBYTE: 1024,
  GENERAL_PHASE_COMPLETED_END_OPTION_ID: 0,
  TIME_UNIT_YEAR_ID: 3,
  ID_BUILDER_FORMAT_DATE_OPTIONS: {
    DD: {
      id: 1,
      value: 'DD',
    },
    MM: {
      id: 2,
      value: 'MM',
    },
    YY: {
      id: 3,
      value: 'YY',
    },
  },
  PUBLISH_STATUSES: {
    PUBLISHED: 10,
    DRAFT: 11,
  },
});

export enum CERTIFICATE_STATUSES_ENUM {
  IMPOSED = 37,
  ARCHIVED = 38,
  ACCEPT = 40,
  REJECT = 41,
  COMPLETE = 43,
  PENDING_VERIFICATION = 42,
  UNDER_AUDIT = 82,
  CORRECTIONS_REQUESTED = 83,
  CREDENTIAL_PAUSED = 84,
  // Due to ICERT01-3224
  DISABLED = 44,
}

export const EDUCATION_HALF_HOURS_PATTERN = '^[0-9]*(\\.5)?$';
export const LOG_HALF_UNIT_PATTERN = '^([1-9]+[0-9]*(\\.5)?|0(\\.5))$';
export const LOG_ONE_DECIMAL_PATTERN = '^([1-9]+[0-9]*(\\.[0-9])?|0(\\.[0-9]))$';
export const ONE_HUNDREDTH_PATTERN = '^([1-9]+[0-9]*(\\.[0-9]{1,2})?|0(\\.[0-9]{1,2}))$';
export const EDUCATION_MIN_HOURS_VALUE = 0.01;

export const DEFAULT_MODAL_OPTIONS: { backdrop: 'static'; keyboard: boolean } = {
  backdrop: 'static',
  keyboard: false,
};

export const FEES_STEP_PAID_BY_NAMES = {
  1: 'Professional',
  2: '3rd party',
};

export const FEES_STEP_PAID_BY = {
  THIRD_PARTY: 2,
  PROFESSIONAL: 1,
};

export const CERTIFICATION_TEMPLATE_NAMES = {
  1: 'General',
  2: 'Test Registration',
};

export const PHASE_INSTANCE_STATUS = {
  COMPLETED: 76,
  INCOMPLETE: 77,
  PENDING_VERIFICATION: 78,
  UNDER_AUDIT: 85,
  CORRECTIONS_REQUESTED: 86,
  CREDENTIAL_PAUSED: 87,
  FAIL: 88,
  ARCHIVED: 92,
};

export const PHASE_INSTANCE_STATUS_NAMES = {
  76: 'Completed',
  77: 'Incomplete',
  78: 'Pending verification',
  85: 'Pending verification',
  86: 'Corrections Requested',
  87: 'Credential Paused',
  88: 'Fail',
  92: 'Archived'
};

export enum CUSTOM_FIELD_TYPES {
  NUMBER = 1,
  STRING = 2,
  TEXTAREA = 3,
  DATE = 4,
  BOOLEAN_SELECT = 6,
  ATTACHMENT = 8,
  PHONE_NUMBER = 12,
  SELECT_COUNTRY = 17,
  SELECT_STATE = 18,
}

export const FIELD_TYPES_WITH_OPTIONS = [
  constants.STEP_CONTENT_ELEMENTS_TYPES.CHECKBOX,
  constants.STEP_CONTENT_ELEMENTS_TYPES.SELECT,
  constants.STEP_CONTENT_ELEMENTS_TYPES.RADIO_OPTIONS,
  constants.STEP_CONTENT_ELEMENTS_TYPES.MULTI_SELECT,
  constants.STEP_CONTENT_ELEMENTS_TYPES.ADA_CODES,
  constants.STEP_CONTENT_ELEMENTS_TYPES.SELECT_COUNTRY,
  constants.STEP_CONTENT_ELEMENTS_TYPES.SELECT_STATE,
];

export enum REPORT_ENTITIES {
  TESTING_WORKFLOW_QUALIFICATION_DASHBOARD = 'testingWorkflowQualificationDashboard',
  TESTING_WORKFLOW_CANDIDATES_DASHBOARD = 'testingWorkflowCandidatesDashboard',
  TESTING_WORKFLOW_SCORE_SUMMARY = 'testingWorkflowScoreSummary',
  TESTING_WORKFLOW_SCORE_DETAIL = 'testingWorkflowScoreDetail',
}

export enum HISTORY_TYPES {
  TO_DO_CREATED = 1,
  TO_DO_UPDATED = 2,
  TO_DO_COMPLETED = 3,
  VERIFIER_JOB_CREATED = 4,
  VERIFIER_JOB_DELETED = 5,
  VERIFIER_JOB_APPROVED = 6,
  VERIFIER_JOB_REJECTED = 7,
  VERIFIER_JOB_PAUSED = 8,
  VERIFIER_JOB_REVERTED = 9,
  NOTE_CREATED = 10,
  VERIFIER_JOB_UPDATED = 11,
  TO_DO_DELETED = 12,
  VERIFIER_JOB_PRIMARY_APPROVED = 13,
  VERIFIER_JOB_SECONDARY_APPROVED = 14,
  VERIFIER_JOB_REQUEST_CORRECTION = 15,
  SUBMISSION_APPROVED = 16,
  SUBMISSION_REJECTED = 17,
}
export const HISTORY_TYPES_MAP = {
  [HISTORY_TYPES.TO_DO_CREATED]: 'To Do Created',
  [HISTORY_TYPES.TO_DO_UPDATED]: 'To Do Updated',
  [HISTORY_TYPES.TO_DO_COMPLETED]: 'To Do Completed',
  [HISTORY_TYPES.VERIFIER_JOB_CREATED]: 'Job Created',
  [HISTORY_TYPES.VERIFIER_JOB_DELETED]: 'Job Deleted',
  [HISTORY_TYPES.VERIFIER_JOB_APPROVED]: 'Job Approved',
  [HISTORY_TYPES.VERIFIER_JOB_REJECTED]: 'Job Rejected',
  [HISTORY_TYPES.VERIFIER_JOB_PAUSED]: 'Job Paused',
  [HISTORY_TYPES.VERIFIER_JOB_REVERTED]: 'Job Reverted',
  [HISTORY_TYPES.NOTE_CREATED]: 'Comment Added',
  [HISTORY_TYPES.VERIFIER_JOB_UPDATED]: 'Job Updated',
  [HISTORY_TYPES.TO_DO_DELETED]: 'To Do Deleted',
  [HISTORY_TYPES.VERIFIER_JOB_PRIMARY_APPROVED]: 'Primary Job Approved',
  [HISTORY_TYPES.VERIFIER_JOB_SECONDARY_APPROVED]: 'Secondary Job Approved',
  [HISTORY_TYPES.VERIFIER_JOB_REQUEST_CORRECTION]: 'Corrections requested',
  [HISTORY_TYPES.SUBMISSION_APPROVED]: 'Submission Approved',
  [HISTORY_TYPES.SUBMISSION_REJECTED]: 'Submission Rejected',
};

export enum CHANGE_DOMAIN_TYPE {
  CHANGE_LOCATION_ONLY = 1,
  IS_RENEW,
}

export enum VERIFICATION_TYPE {
  NO_VERIFICATION = 1,
  VERIFICATION_REQUIRED,
  DOUBLE_VERIFICATION_REQUIRED,
}

export const VERIFICATION_TYPE_NAME = {
  [VERIFICATION_TYPE.VERIFICATION_REQUIRED]: 'Primary Verification Only',
  [VERIFICATION_TYPE.DOUBLE_VERIFICATION_REQUIRED]: 'Primary & Secondary Verification',
  [VERIFICATION_TYPE.NO_VERIFICATION]: 'No Verification Required',
};

export const TW_VERIFICATION_TYPE_NAME = {
  [VERIFICATION_TYPE.VERIFICATION_REQUIRED]: 'Yes',
  [VERIFICATION_TYPE.NO_VERIFICATION]: 'No',
};

export const VERIFICATION_OPTIONS: SmartForm.FieldOption[] = [
  {
    id: VERIFICATION_TYPE.VERIFICATION_REQUIRED,
    name: VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.VERIFICATION_REQUIRED],
  },
  {
    id: VERIFICATION_TYPE.DOUBLE_VERIFICATION_REQUIRED,
    name: VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.DOUBLE_VERIFICATION_REQUIRED],
  },
  {
    id: VERIFICATION_TYPE.NO_VERIFICATION,
    name: VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.NO_VERIFICATION],
  },
];

export enum PROOF_OF_EDUCATION {
  OPTIONAL = 0,
  REQUIRED = 1,
  NOT_REQUIRED = 2,
}

export const PROOF_OF_EDUCATION_NAME = {
  [PROOF_OF_EDUCATION.OPTIONAL]: 'No - Optional',
  [PROOF_OF_EDUCATION.REQUIRED]: 'Yes',
  [PROOF_OF_EDUCATION.NOT_REQUIRED]: 'No - Do NOT Display Option',
};

export const PROOF_OF_EDUCATION_OPTIONS: SmartForm.FieldOption[] = [
  {
    id: PROOF_OF_EDUCATION.OPTIONAL,
    name: PROOF_OF_EDUCATION_NAME[PROOF_OF_EDUCATION.OPTIONAL],
  },
  {
    id: PROOF_OF_EDUCATION.NOT_REQUIRED,
    name: PROOF_OF_EDUCATION_NAME[PROOF_OF_EDUCATION.NOT_REQUIRED],
  },
  {
    id: PROOF_OF_EDUCATION.REQUIRED,
    name: PROOF_OF_EDUCATION_NAME[PROOF_OF_EDUCATION.REQUIRED],
  },
];

export enum SHARE_CE_UNITS {
  NO = 0,
  YES = 1,
}

export const SHARE_CE_UNITS_NAME = {
  [SHARE_CE_UNITS.NO]: 'No',
  [SHARE_CE_UNITS.YES]: 'Yes',
};

export const SHARE_CE_UNITS_OPTIONS: SmartForm.FieldOption[] = [
  {
    id: SHARE_CE_UNITS.NO,
    name: SHARE_CE_UNITS_NAME[SHARE_CE_UNITS.NO],
  },
  {
    id: SHARE_CE_UNITS.YES,
    name: SHARE_CE_UNITS_NAME[SHARE_CE_UNITS.YES],
  },
];

export const VERIFICATION_REQUIRED_OPTIONS: SmartForm.FieldOption[] = [
  {
    id: VERIFICATION_TYPE.VERIFICATION_REQUIRED,
    name: VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.VERIFICATION_REQUIRED],
  },
  {
    id: VERIFICATION_TYPE.DOUBLE_VERIFICATION_REQUIRED,
    name: VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.DOUBLE_VERIFICATION_REQUIRED],
  },
];

export const TW_VERIFICATION_OPTIONS: SmartForm.FieldOption[] = [
  {
    id: VERIFICATION_TYPE.VERIFICATION_REQUIRED,
    name: TW_VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.VERIFICATION_REQUIRED],
  },
  {
    id: VERIFICATION_TYPE.NO_VERIFICATION,
    name: TW_VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.NO_VERIFICATION],
  },
];

export const TW_VERIFICATION_OPTIONS_REQUIRED_DISABLED: SmartForm.FieldOption[] = [
  {
    id: VERIFICATION_TYPE.VERIFICATION_REQUIRED,
    name: TW_VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.VERIFICATION_REQUIRED],
    disabled: true,
  },
  {
    id: VERIFICATION_TYPE.NO_VERIFICATION,
    name: TW_VERIFICATION_TYPE_NAME[VERIFICATION_TYPE.NO_VERIFICATION],
  },
];

export enum LOG_STEP_SUBMISSION_STATUS {
  SUBMITTED = 113,
  COMPLETED = 114,
  PENDING_VERIFICATION = 115,
  REJECTED = 116,
  REJECTED_ARCHIVED = 117,
  PENDING_SUPERVISOR = 118,
  REJECTED_BY_SUPERVISOR = 119,
  ACCEPTED_BY_SUPERVISOR = 120,
  PENDING_SECONDARY_SUPERVISOR = 121,
  ACCEPTED_BY_SECONDARY_SUPERVISOR = 122,
}

export const LOG_STEP_SUBMISSION_STATUS_NAME = {
  [LOG_STEP_SUBMISSION_STATUS.SUBMITTED]: 'Submitted',
  [LOG_STEP_SUBMISSION_STATUS.COMPLETED]: 'Completed',
  [LOG_STEP_SUBMISSION_STATUS.PENDING_VERIFICATION]: 'Pending verification',
  [LOG_STEP_SUBMISSION_STATUS.REJECTED]: 'Rejected',
  [LOG_STEP_SUBMISSION_STATUS.REJECTED_ARCHIVED]: 'Archived',
  [LOG_STEP_SUBMISSION_STATUS.PENDING_SUPERVISOR]: 'Pending {supervisorTitle}',
  [LOG_STEP_SUBMISSION_STATUS.REJECTED_BY_SUPERVISOR]: 'Pending {supervisorTitle}',
  [LOG_STEP_SUBMISSION_STATUS.ACCEPTED_BY_SUPERVISOR]: 'Pending {supervisorTitle}',
  [LOG_STEP_SUBMISSION_STATUS.PENDING_SECONDARY_SUPERVISOR]: 'Pending {supervisorTitle}',
  [LOG_STEP_SUBMISSION_STATUS.ACCEPTED_BY_SECONDARY_SUPERVISOR]: 'Pending {supervisorTitle}',
};

export enum LOG_STEP_INVITATION_STATUS {
  REJECTED = 70,
  ACCEPTED = 71,
  PENDING = 72,
  EXPIRED = 73,
  CANCELLED = 74,
  PENDING_SECONDARY_SUPERVISOR = 75,
}

export const LOG_STEP_INVITATION_STATUS_NAME = {
  [LOG_STEP_INVITATION_STATUS.REJECTED]: 'Rejected',
  [LOG_STEP_INVITATION_STATUS.ACCEPTED]: 'Accepted',
  [LOG_STEP_INVITATION_STATUS.PENDING]: 'Pending',
  [LOG_STEP_INVITATION_STATUS.EXPIRED]: 'Expired',
  [LOG_STEP_INVITATION_STATUS.CANCELLED]: 'Cancelled',
  [LOG_STEP_INVITATION_STATUS.PENDING_SECONDARY_SUPERVISOR]: '-',
};

export enum LogNewViewEnum {
  NONE,
  ONLY_FORM,
  ONLY_CRUD,
  ONLY_INVITE,
  ONLY_INVITE_PREVIEW,
  CRUD_INVITE_PREVIEW,
  CRUD_INVITE_PREVIEW_NEW_INVITE,
  SUPERVISOR_BOTH,
  ONLY_FORM_PREVIEW,
}

export enum LogNewFormEnum {
  FORM,
  CORRECTION,
}

export enum ORGANIZATION_TYPE {
  BOARD = 1,
  EMPLOYER = 2,
}

export const defaultOptionsValue = {
  YES: 'Yes',
  NO: 'No',
};

export const defaultOptions = [
  {
    id: true,
    name: defaultOptionsValue.YES,
  },
  {
    id: false,
    name: defaultOptionsValue.NO,
  },
];

export const yesDisabledDefaultOptions = [
  {
    id: true,
    name: defaultOptionsValue.YES,
    disabled: true,
  },
  {
    id: false,
    name: defaultOptionsValue.NO,
  },
];

export const noDisabledDefaultOptions = [
  {
    id: true,
    name: defaultOptionsValue.YES,
  },
  {
    id: false,
    name: defaultOptionsValue.NO,
    disabled: true,
  },
];
