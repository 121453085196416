import { PropsMap, propsMapToArray } from 'utils/constants.helpers';

export enum SUPERVISOR_STATUSES {
  REJECTED = 70,
  ACCEPTED = 71,
  PENDING = 72,
  EXPIRED = 73,
  CANCELLED = 74,
  PENDING_SECONDARY_SUPERVISOR = 75,
}

export const SUPERVISOR_STATUS_NAMES = Object.freeze({
  [SUPERVISOR_STATUSES.REJECTED]: 'Rejected',
  [SUPERVISOR_STATUSES.ACCEPTED]: 'Accepted',
  [SUPERVISOR_STATUSES.PENDING]: 'Invitation pending',
  [SUPERVISOR_STATUSES.EXPIRED]: 'Expired',
  [SUPERVISOR_STATUSES.CANCELLED]: 'Cancelled',
  [SUPERVISOR_STATUSES.PENDING_SECONDARY_SUPERVISOR]: 'Invitation status:-',
});

// COMPLIANCE_STATUSES === VERIFIER_JOBS_STATUSES
export enum COMPLIANCE_STATUSES {
  PENDING = 65,
  REJECTED = 66,
  VERIFIED = 67,
  PAUSE = 69,
  REQUEST_CORRECTION = 89,
}

export const COMPLIANCE_STATUS_NAMES = Object.freeze({
  [COMPLIANCE_STATUSES.PENDING]: 'Pending verification',
  [COMPLIANCE_STATUSES.REJECTED]: 'Rejected',
  [COMPLIANCE_STATUSES.VERIFIED]: 'Verified',
  [COMPLIANCE_STATUSES.PAUSE]: 'Paused',
  [COMPLIANCE_STATUSES.REQUEST_CORRECTION]: 'Requested corrections',
});

export enum STEP_TYPE_STATUSES {
  INCOMPLETE = 47,
  COMPLETED = 48,
  PENDING_VERIFICATION = 49,
  PASSED_DUE_DATE = 50,
  PARTIALLY_COMPLETED = 75,
  ARCHIVED = 91,
}

export const STEP_TYPE_STATUS_NAMES = Object.freeze({
  [STEP_TYPE_STATUSES.INCOMPLETE]: 'Incomplete',
  [STEP_TYPE_STATUSES.COMPLETED]: 'Completed',
  [STEP_TYPE_STATUSES.PENDING_VERIFICATION]: 'Pending verification',
  [STEP_TYPE_STATUSES.PASSED_DUE_DATE]: 'Passed due date',
  [STEP_TYPE_STATUSES.PARTIALLY_COMPLETED]: 'Partially completed',
  [STEP_TYPE_STATUSES.ARCHIVED]: 'Archived',
  REQUIRED: 'Required',
});

export const CERTIFICATION_STATUSES = Object.freeze({
  ACTIVE: 1,
  DELETED: 2,
  INACTIVE: 3,
});

export enum STEP_COLOR_STATUSES {
  DEFAULT = 1,
  ORANGE = 2,
  RED = 3,
  PENDING = 4,
  COMPLETED = 5,
  PARTIALLY_COMPLETED = 6,
  // Due to ICERT01-3224
  DISABLED = 7,
  FAILED = 8,
  ARCHIVED = 9,
}

export const CERTIFICATION_COMPLIANCE_STATUSES: any = [
  {
    label: 'No alert',
    color: STEP_COLOR_STATUSES.DEFAULT,
  },
  {
    label: 'Yellow',
    color: STEP_COLOR_STATUSES.ORANGE,
  },
  {
    label: 'Red',
    color: STEP_COLOR_STATUSES.RED,
  },
  {
    label: 'Pending verification',
    color: STEP_COLOR_STATUSES.PENDING,
  },
  {
    label: 'Completed',
    color: STEP_COLOR_STATUSES.COMPLETED,
  },
  {
    label: 'Partially completed',
    color: STEP_COLOR_STATUSES.PARTIALLY_COMPLETED,
  },
  {
    label: 'Archived',
    color: STEP_COLOR_STATUSES.ARCHIVED,
  }
];

export const STEP_AUDIT_STATUSES = {
  UNDER_AUDIT: 88,
  CORRECTIONS_REQUESTED: 89,
  CREDENTIAL_PAUSED: 90,
};

export enum STEP_TYPES {
  DYNAMIC = 1,
  FEES = 2,
  OTHER_CERTIFICATE = 3,
  EVIDENCE_FILE = 4,
  EDUCATION = 5,
  TEST = 6,
  REGISTRATION_FORM = 7,
  VERIFICATION = 8,
  LOG = 9,
  PRACTICE_TEST = 10,
  TEST_REGISTRATION_VERIFICATION = 11,
  QUALIFICATION = 12,
  TEST_REGISTRATION = 13,
  ADA = 14,
  TEST_FEE = 15,
  SCHEDULING = 16,
  TEST_SCHEDULING_VERIFICATION = 17,
  TEST_TAKING = 18,
  TEST_TAKING_VERIFICATION = 19,
  TEST_SCORE = 20,
  TEST_SCORE_VALIDATION = 21,
  FOLLOW_UP = 22,
  LOG_NEW = 25,
  LICENSE_VERIFICATION = 26, // Verification Documentation
  LICENSE_RENEWAL = 27,
  ONBOARDING = 28,
  ONBOARDING_PSV_VERIFICATION = 29,
}

export const STEP_TYPES_NAMES = Object.freeze({
  [STEP_TYPES.DYNAMIC]: 'Dynamic form',
  [STEP_TYPES.FEES]: 'Fees',
  [STEP_TYPES.OTHER_CERTIFICATE]: 'Other credential',
  [STEP_TYPES.EVIDENCE_FILE]: 'Evidence file',
  [STEP_TYPES.EDUCATION]: 'Education',
  [STEP_TYPES.TEST]: 'Test',
  [STEP_TYPES.REGISTRATION_FORM]: 'Test Registration Form',
  [STEP_TYPES.VERIFICATION]: 'Verification',
  [STEP_TYPES.LOG]: 'Log',
  [STEP_TYPES.PRACTICE_TEST]: 'Practice Test',
  [STEP_TYPES.TEST_REGISTRATION_VERIFICATION]: 'Test Registration Verification',
  [STEP_TYPES.QUALIFICATION]: 'Qualification',
  [STEP_TYPES.TEST_REGISTRATION]: 'Test Registration',
  [STEP_TYPES.ADA]: 'ADA',
  [STEP_TYPES.TEST_FEE]: 'Test Fee',
  [STEP_TYPES.SCHEDULING]: 'Scheduling',
  [STEP_TYPES.TEST_SCHEDULING_VERIFICATION]: 'Test Scheduling Verification',
  [STEP_TYPES.TEST_TAKING]: 'Test Taking',
  [STEP_TYPES.TEST_TAKING_VERIFICATION]: 'Test Taking Verification',
  [STEP_TYPES.TEST_SCORE]: 'Test Score',
  [STEP_TYPES.TEST_SCORE_VALIDATION]: 'Test Score Validation',
  [STEP_TYPES.FOLLOW_UP]: 'Follow Up',
  [STEP_TYPES.LOG_NEW]: 'Log (New)',
  [STEP_TYPES.LICENSE_VERIFICATION]: 'Verification Documentation',
  [STEP_TYPES.LICENSE_RENEWAL]: 'Renewal',
  [STEP_TYPES.ONBOARDING]: 'Onboarding',
  [STEP_TYPES.ONBOARDING_PSV_VERIFICATION]: 'Onboarding PSV Verification step',
});

export enum PHASE_TYPES {
  GENERAL = 1,
  FOLLOW_UP = 2,
  QUALIFICATION = 3,
  RE_SCHEDULING = 4,
  RE_TAKE = 5,
  REGISTRATION = 6,
  SCHEDULING = 7,
  SCORE_REPORTING = 8,
  TEST_TAKING = 9,
  RECERTIFICATION = 10,
  LICENSE_CONFIRMATION_INITIAL = 11,
  LICENSE_CONFIRMATION_RECERTIFICATION = 12,
  LICENSE_VERIFICATION = 13,
  MISCELLANEOUS = 14,
  LICENSE_RENEWAL = 15,
  FOLLOW_ON = 16,
  ONBOARDING = 17,
}

export const PHASE_TYPES_NAMES = {
  [PHASE_TYPES.GENERAL]: 'Initial',
  [PHASE_TYPES.QUALIFICATION]: 'Qualification',
  [PHASE_TYPES.REGISTRATION]: 'Registration',
  [PHASE_TYPES.TEST_TAKING]: 'Test Taking',
  [PHASE_TYPES.SCHEDULING]: 'Scheduling',
  [PHASE_TYPES.RE_SCHEDULING]: 'Re-Scheduling',
  [PHASE_TYPES.SCORE_REPORTING]: 'Score Reporting',
  [PHASE_TYPES.RE_TAKE]: 'Re-Take',
  [PHASE_TYPES.FOLLOW_UP]: 'Follow-Up',
  [PHASE_TYPES.MISCELLANEOUS]: 'Misc',
  [PHASE_TYPES.RECERTIFICATION]: 'Recertification',
  [PHASE_TYPES.LICENSE_VERIFICATION]: 'License Verification',
  [PHASE_TYPES.LICENSE_RENEWAL]: 'License Renewal',
  [PHASE_TYPES.LICENSE_CONFIRMATION_INITIAL]: 'License Confirmation (Initial)',
  [PHASE_TYPES.LICENSE_CONFIRMATION_RECERTIFICATION]: 'License Confirmation (Recertification)',
  [PHASE_TYPES.FOLLOW_ON]: 'Follow-On',
  [PHASE_TYPES.ONBOARDING]: 'Onboarding',
};

export enum PUBLISH_STATUSES {
  PUBLISHED = 10,
  DRAFT = 11,
}

export const PUBLISH_STATUSES_NAME = {
  [PUBLISH_STATUSES.PUBLISHED]: 'Published',
  [PUBLISH_STATUSES.DRAFT]: 'Draft',
};

export enum EMPLOYER_REQUEST_STATUS {
  REQUESTED = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  REVOKED = 4,
}

export const EMPLOYER_REQUEST_STATUS_NAMES = {
  [EMPLOYER_REQUEST_STATUS.REQUESTED]: 'Pending',
  [EMPLOYER_REQUEST_STATUS.ACCEPTED]: 'Tracked',
  [EMPLOYER_REQUEST_STATUS.DECLINED]: 'Rejected',
  [EMPLOYER_REQUEST_STATUS.REVOKED]: 'Revoked',
};

export enum PROFILE_STATUSES {
  ACTIVATED = 1,
  DELETED = 2,
  DEACTIVATED = 3,
}

export const PROFILE_STATUS_NAMES = {
  [PROFILE_STATUSES.ACTIVATED]: 'Activated',
  [PROFILE_STATUSES.DELETED]: 'Deleted',
  [PROFILE_STATUSES.DEACTIVATED]: 'Deactivated',
};

export enum CERTIFICATE_STATUSES {
  Imposed = 37,
  Archived = 38,
  Accept = 40,
  Reject = 41,
  Complete = 43,
  'Pending verification' = 42,
  Disabled = 44,
}

export const COLUMN_TYPE = Object.freeze({
  STATUS_COLOR: 'STATUS_COLOR',
});
